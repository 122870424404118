<template>
  <el-popover placement="left" width="380" trigger="manual" v-model="visible">
    <div class="accessBox">
      <h3>* 拖动图标放置首页，可更换快捷入口</h3>
      <div class="quickAccess">
      <!--<draggable tag="div" :list="accessDatas" class="quickAccess" group="setItems" forceFallback="true"-->
                 <!--:disabled="dragDisabled" @end="end"-->
                 <!--:options="{ghostClass:'ghostClass',fallbackClass:'fallbackClass'}">-->
        <div v-for="data in accessDatas" :key="data.id">
          <img src="@/assets/img/main/icon-zuzhigoujia.svg" alt="">
          <p>{{data.name}}</p>
        </div>
      <!--</draggable>-->
      </div>
      <div id="save">
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </div>
    </div>
    <div slot="reference" style="outline: none;">
      <h2Title title="快捷入口" style="margin-top: 10px;">
        <!--  自定义入口暂时不要-->
<!--        <el-link type="info" class="shortMenu" @click="showPopover()">-->
<!--          <svg class="icon" aria-hidden="true">-->
<!--            <use xlink:href="#icon-zidingyishaixuan"></use>-->
<!--          </svg>-->
<!--          自定义入口-->
<!--        </el-link>-->
      </h2Title>
      <div v-if="this.onlyFinanceAuthor" class="demo-form-inline">
        <div class="quickAccess">
          <div @click="_toPage('/agreement/agreement_agreementIndex.action')">
                           <span class="kjIcon">
                    <svg class="icon" aria-hidden="true">
                       <use xlink:href="#icon-fafangpici"></use>
                     </svg>
                 </span>
            <p>协议管理 </p>
          </div>
          <div @click="_toPage('/cusm/cusmanager.action')">
          <span class="kjIcon">
             <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-kehuxinxi"></use>
              </svg>
          </span>
            <p>客户信息 </p>
          </div>
          <div @click="_toPage('/entrust/distributionBatchIndex')">
                    <span class="kjIcon">
             <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-fafangpici"></use>
              </svg>
          </span>
            <p>支付确认 </p>
          </div>
          <div @click="_toPage('/consignor/payoutBills_packedRTicket.action')">
                    <span class="kjIcon">
             <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-tuipiao"></use>
              </svg>
          </span>
            <p>退票管理 </p>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="quickAccess">
          <!--<draggable tag="div" :list="quickAccess" class="quickAccess" :class="{'showMask':visible}" group="setItems"-->
          <!--forceFallback="true" :disabled="dragDisabled"-->
          <!--:options="{ghostClass:'ghostClass',fallbackClass:'fallbackClass'}">-->
          <!--        <div v-for="(access,i) in quickAccess" :key="access.id" v-if="i<6" @click="openMenu(i)">-->
          <!--          <img src="@/assets/img/main/icon-zuzhigoujia.svg" alt="">-->
          <!--          <p>{{access.name}} <i v-if="visible" class="el-icon-error" @click.stop="delAccess(i)"></i></p>-->
          <!--        </div>-->
          <div @click="_toPage('/cusm/cusmanager.action')">
            <span class="kjIcon">
               <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-kehuxinxi"></use>
                </svg>
            </span>
            <p>客户信息 </p>
          </div>
          <div @click="_toPage('/wt/detailImportBatchTabs')">
                      <span class="kjIcon">
               <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-kehuzhangdan"></use>
                </svg>
            </span>
            <p>薪资导入 </p>
          </div>
          <div v-if="userInfo.userType!='2'" @click="_toPage('/entrust/socialSecurityImportList')">
                      <span class="kjIcon">
               <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-shebaochaxun"></use>
                </svg>
            </span>
            <p>社保导入 </p>
          </div>
          <div @click="_toPage('/entrust/distributionBatchIndex')">
                      <span class="kjIcon">
               <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-fafangpici"></use>
                </svg>
            </span>
            <p>支付确认 </p>
          </div>
          <div @click="_toPage('/entrust/paymentBills')">
                      <span class="kjIcon">
               <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-zhangdan"></use>
                </svg>
            </span>
            <p>客户账单 </p>
          </div>
          <div @click="_toPage('/consignor/batchIndex.action')">
                      <span class="kjIcon">
               <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-icon-test"></use>
                </svg>
            </span>
            <p>委托批次</p>
          </div>
          <div @click="_toPage('/agreementInfo/agreementInfo_applyIndex.action')">
                      <span class="kjIcon">
               <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-qingdan2"></use>
                </svg>
            </span>
            <p>申请管理 </p>
          </div>
          <div v-if="userInfo.userType!='2'" @click="_toPage('/consignee/batchIndex.action')">
                      <span class="kjIcon">
               <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-fafangpici"></use>
                </svg>
            </span>
            <p>受托批次 </p>
          </div>

          <div @click="_toPage('/agreement/agreement_agreementIndex.action')">
                             <span class="kjIcon">
                      <svg class="icon" aria-hidden="true">
                         <use xlink:href="#icon-fafangpici"></use>
                       </svg>
                   </span>
            <p>协议管理 </p>
          </div>
        </div>
        <!--</draggable>-->
      </div>
    </div>
  </el-popover>


</template>

<script>
  // import {
  // 	addQuickEntryR,
  // 	queryQuickEntryList
  // } from "@/api/msg/msg";
  import draggable from "vuedraggable";
  import h2Title from "./title.vue";
  import router from "../../router/router";
  import {mapGetters} from "vuex";


  export default {
    name: "quickAccess",
    props: {
      showMask: {
        type: Boolean,
        default: () => (
          false
        )
      }
    },
    components: {
      h2Title
    },
    computed: {
      ...mapGetters(['userInfo']),
      ids() {
        return this.quickAccess.map(item => {
          return item.id;
        });
      }
    },
    watch: {
      visible(val, oldVal) { //普通的watch监听
        this.$emit("update:showMask", val);
      }
    },
    data() {
      return {
        visible: false,
        quickAccess: [],
        datas: [],
        entryIds: [],
        onlyFinanceAuthor: false,
      };
    },
    created() {
      // queryQuickEntryList(1).then(res => {
      // 	this.datas = res.data.data;
      // 	this._queryQuickEntryList(true);
      // })
      this.datas = [
        {
        "name": "组织管理",
        "icon": null,
        "enName": "Organization management",
        "orderby": "1",
        "remark": null,
        "menuType": "1",
        "id": "83DBBEC3C40F400AB2B6BC1C1B81A741",
        "type": null,
        "url": "/org/toOrgPage.action",
        "status": "00901"
      }, {
        "name": "员工管理",
        "icon": null,
        "enName": "Employee management",
        "orderby": "2",
        "remark": null,
        "menuType": "1",
        "id": "4C015940F62B4A03A7C0D535712CD6F7",
        "type": null,
        "url": "/per/toPerList.action?sysFunctionCode=OTA4NDAwMTY-",
        "status": "00901"
      }, {
        "name": "薪资历史",
        "icon": null,
        "enName": null,
        "orderby": "3",
        "remark": null,
        "menuType": "1",
        "id": "E87E38E9C04E43C08990DC3A6C0E9568",
        "type": null,
        "url": "/wage/wageHistory",
        "status": "00901"
      }, {
        "name": "薪资发放",
        "icon": null,
        "enName": null,
        "orderby": "3",
        "remark": null,
        "menuType": "1",
        "id": "929113EF942F474380A65FBC3B82D292",
        "type": null,
        "url": "/wage/paymentWage",
        "status": "00901"
      }, {
        "name": "入职管理",
        "icon": null,
        "enName": "Onboarding management",
        "orderby": "3",
        "remark": null,
        "menuType": "1",
        "id": "3CCEA6FA5BC644099CECEFAE50E841CB",
        "type": null,
        "url": "/per/perEntryPage.action",
        "status": "00901"
      }, {
        "name": "薪酬审批",
        "icon": null,
        "enName": null,
        "orderby": "3",
        "remark": null,
        "menuType": "1",
        "id": "FE497848A1EA4DC2B5E49E4478F6130C",
        "type": null,
        "url": "/wage/salaryApproval",
        "status": "00901"
      }, {
        "name": "数据准备",
        "icon": null,
        "enName": null,
        "orderby": "3",
        "remark": null,
        "menuType": "1",
        "id": "66E68D691DCA4B1B827E08EC55CD37F0",
        "type": null,
        "url": "/wage/data-ready-template",
        "status": "00901"
      }, {
        "name": "考勤月报",
        "icon": null,
        "enName": null,
        "orderby": "3",
        "remark": null,
        "menuType": "1",
        "id": "4141478B9ADA4AB093D18E265D447D67",
        "type": null,
        "url": "/atndmonthlyreport/toindex.action",
        "status": "00901"
      }, {
        "name": "岗位管理",
        "icon": null,
        "enName": "Job management",
        "orderby": "4",
        "remark": null,
        "menuType": "1",
        "id": "E62CDEEB5A824D44B5DA250BC9F5E72A",
        "type": null,
        "url": "/job/toJobPage.action",
        "status": "00901"
      }, {
        "name": "休假查询",
        "icon": null,
        "enName": "Leave inquiry",
        "orderby": "5",
        "remark": null,
        "menuType": "1",
        "id": "C34C6B0A78C14D3EB2BD384B5550FE28",
        "type": null,
        "url": "/vacnstandard/queryindex.action",
        "status": "00901"
      }, {
        "name": "休假设置",
        "icon": null,
        "enName": "Vacation standard setting",
        "orderby": "6",
        "remark": null,
        "menuType": "1",
        "id": "C168ADF17CC944748FD1906B2E9091E6",
        "type": null,
        "url": "/vacnstandard/toindex.action",
        "status": "00901"
      }, {
        "name": "审批设置",
        "icon": null,
        "enName": "Approval setting",
        "orderby": "7",
        "remark": null,
        "menuType": "1",
        "id": "9ED7265AD20E4199A31F6FDCD8B08F56",
        "type": null,
        "url": "/approval/toindex.action",
        "status": "00901"
      }, {
        "name": "考勤设置",
        "icon": null,
        "enName": " Attendance setting",
        "orderby": "8",
        "remark": null,
        "menuType": "1",
        "id": "CE47AF1D650145F1BE9C82D93A3C10C7",
        "type": null,
        "url": "/atndset/toindex.action",
        "status": "00901"
      }];
      this._isOnlyFinanceAuthor();
      this._queryQuickEntryList(true);
    },
    methods: {
      _toPage(path){
        this.$router.push({path: path})
      },
      addAccess(data) {
        this.quickAccess.push(data);
      },
      _isOnlyFinanceAuthor(){
        this.onlyFinanceAuthor = (this.userInfo.userRoleList.length === 1 && "4d3060735c5467fdded5cd4dbc897ef8" === this.userInfo.userRoleList[0].roleId);
      },
      _queryQuickEntryList(isFirst) {
        // queryQuickEntryList(2).then(res => {
        // 	this.quickAccess = res.data.data;
        this.quickAccess = [
          {
          "name": "组织管理",
          "icon": null,
          "enName": "Organization management",
          "orderby": "1",
          "remark": null,
          "menuType": "1",
          "id": "83DBBEC3C40F400AB2B6BC1C1B81A741",
          "type": null,
          "url": "/org/toOrgPage.action",
          "status": "00901"
        }, {
          "name": "员工管理",
          "icon": null,
          "enName": "Employee management",
          "orderby": "2",
          "remark": null,
          "menuType": "1",
          "id": "4C015940F62B4A03A7C0D535712CD6F7",
          "type": null,
          "url": "/per/toPerList.action?sysFunctionCode=OTA4NDAwMTY-",
          "status": "00901"
        }, {
          "name": "薪资历史",
          "icon": null,
          "enName": null,
          "orderby": "3",
          "remark": null,
          "menuType": "1",
          "id": "E87E38E9C04E43C08990DC3A6C0E9568",
          "type": null,
          "url": "/wage/wageHistory",
          "status": "00901"
        }, {
          "name": "薪资发放",
          "icon": null,
          "enName": null,
          "orderby": "3",
          "remark": null,
          "menuType": "1",
          "id": "929113EF942F474380A65FBC3B82D292",
          "type": null,
          "url": "/wage/paymentWage",
          "status": "00901"
        }, {
          "name": "入职管理",
          "icon": null,
          "enName": "Onboarding management",
          "orderby": "3",
          "remark": null,
          "menuType": "1",
          "id": "3CCEA6FA5BC644099CECEFAE50E841CB",
          "type": null,
          "url": "/per/perEntryPage.action",
          "status": "00901"
        }, {
          "name": "薪酬审批",
          "icon": null,
          "enName": null,
          "orderby": "3",
          "remark": null,
          "menuType": "1",
          "id": "FE497848A1EA4DC2B5E49E4478F6130C",
          "type": null,
          "url": "/wage/salaryApproval",
          "status": "00901"
        }];
        let ids = this.quickAccess.map(item => {
          return item.id;
        });
        if (isFirst) {
          this.entryIds = ids;
        }
        this.accessDatas = this.datas.filter(data => {
          return ids.indexOf(data.id) < 0;
        });
        console.log("this.accessDatas",this.accessDatas)
        // })
      },
      delAccess(i) {
        this.quickAccess.splice(i, 1);
        let ids = this.quickAccess.map(item => {
          return item.id;
        });
        this.accessDatas = this.datas.filter(data => {
          return ids.indexOf(data.id) < 0;
        });
      },
      showPopover() {
        this.visible = true;
      },
      onSubmit() {
        if (this.ids.join(",") == this.entryIds.join(",")) {
          this.visible = false;
          return;
        }
        if (this.ids.length > 6) {
          this.$message.warning("快捷入口最多选择6个");
          return;
        }
        addQuickEntryR({
          ids: this.ids.join(",")
        }).then(res => {
          if (res.data.code == "0") {
            this.$message.success("保存成功");

            this.visible = false;
          }
          this._queryQuickEntryList(true);
        });
      },
      end: function(evt) {
        // evt.item //可以知道拖动的本身
        // evt.to    // 可以知道拖动的目标列表
        // evt.from  // 可以知道之前的列表
        // evt.oldIndex  // 可以知道拖动前的位置
        // evt.newIndex  // 可以知道拖动后的位置
        var currentIndex = evt.newIndex;
        if (this.quickAccess.length > 6) {
          this.$message.warning("快捷入口最多选择6个");
          var that = this;
          setTimeout(function() {
            var currentAccess = that.quickAccess.splice(currentIndex, 1);//移除当前元素
            that.accessDatas.push(currentAccess[0]);
          }, 300);
        }
      },
      //打开路由
      openMenu(i) {
        if (this.visible) {
          return;
        }
        var _path = this.quickAccess[i].url;
        if (_path) {
          router.push({ path: _path });
        }
      }
    }
  };
</script>

<style scoped>
  .accessBox .quickAccess {
    box-shadow: none;
    height: auto;
    justify-content: flex-start;
  }

  .quickAccess {
    height: 440px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 4px 0px rgba(187, 187, 187, 0.25);
    border-radius: 4px;
    padding: 20px 0;
    box-sizing: border-box;
    display: flex;
    align-content: flex-start;
    /* justify-content: space-between; */
    flex-wrap: wrap;

  }

  .showMask {
    position: relative;
    z-index: 10;
  }

  .icon {
    font-size: 53px;
    color: #ccc;
  }

  .quickAccess > div {
    width: 60px;
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    height: 80px;
    margin: 2px calc(16.5% - 30px) 10px;
  }

  .quickAccess > div img {
    display: inline-block;
    margin: 0 auto 5px;
  }

  .quickAccess p {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    position: relative;
  }

  .shortMenu >>> .el-link--inner {
    font-size: 13px;
    color: rgba(153, 153, 153, 1);
  }

  .shortMenu >>> .icon {
    margin-right: 8px;
    font-size: 15px;
  }

  .el-link.is-underline:hover:after {
    display: none;
  }

  .accessBox h3 {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 17px;
  }

  #save {
    text-align: center;
    margin-top: 10px;
  }

  #save >>> .el-button {
    background: rgba(255, 134, 121, 1);
  }

  .el-icon-error {
    font-size: 14px;
    color: rgba(199, 199, 199, 1);
    position: absolute;
    top: -60px;
  }
  .kjIcon{
    width: 53px;
    height: 53px;
    background: #349af2;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .kjIcon .icon{
    font-size: 32px;
    color:#ffffff;
  }
</style>
