<template>
  <div class="home">
    <el-row :gutter="20">
      <el-col :span="17">
        <el-row :gutter="20" class="businessBox">
          <el-col :span="12" v-if="userInfo.userType=='2'">
            <div class="home-title">客户</div>
            <div class="business">
              <p><span>本月执行客户数:</span><span class="orange">{{num.cusHandleNum}}</span></p>
              <p><span>雇员数: </span><span class="blue">{{num.cusHandEmpNum}}</span></p>
            </div>
          </el-col>
          <el-col :span="12" v-if="userInfo.userType=='2'">
            <div class="home-title">委托业务</div>
            <div class="business">
              <p><span>已导入批次:</span><span class="orange">{{num.wtBatchNum}}</span></p>
              <p><span>客户数: </span><span class="green">{{num.wtCusNum}}</span></p>
              <p><span>雇员数: </span><span class="blue">{{num.wtEmpNum}}</span></p>
            </div>
          </el-col>
          <el-col :span="8" v-if="userInfo.userType!='2'">
            <div class="home-title">客户</div>
            <div class="business">
              <p><span>本月执行客户数:</span><span class="orange">{{num.cusHandleNum}}</span></p>
              <p><span>雇员数: </span><span class="blue">{{num.cusHandEmpNum}}</span></p>
            </div>
          </el-col>
          <el-col :span="8" v-if="userInfo.userType!='2'">
            <div class="home-title">委托业务</div>
            <div class="business">
              <p><span>已导入批次:</span><span class="orange">{{num.wtBatchNum}}</span></p>
              <p><span>客户数: </span><span class="green">{{num.wtCusNum}}</span></p>
              <p><span>雇员数: </span><span class="blue">{{num.wtEmpNum}}</span></p>
            </div>
          </el-col>

          <el-col :span="8" v-if="userInfo.userType!='2'">
            <div class="home-title">受托业务</div>
            <div class="business">
              <p><span>已接收批次:</span><span class="orange">{{num.stBatchNum}}</span></p>
              <p><span>客户数:</span><span class="green">{{num.stCusNum}}</span></p>
              <p><span>雇员数:</span><span class="blue">{{num.stEmpNum}}</span></p>
            </div>
          </el-col>
        </el-row>
        <el-row class="processLists">
          <el-col :span="12" v-if="!this.onlyFinanceAuthor">
            <div class="processList" @click="_toPage(num.backBatch,'/wt/detailImportBatchTabs')" >
              <span class="processList-icon processListC1">
                 <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-tuihui-3"></use>
                  </svg>
              </span>
              <div class="processList-detail">
                <p>受委托退回 {{num.backBatch}} 个</p>
                <p>退回明细批次数</p>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="processList" @click="_toPage(num.backBank,'/consignor/payoutBills_packedRTicket.action')" >
              <span class="processList-icon processListC2">
                 <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-yinhang-2"></use>
                  </svg>
              </span>
              <div class="processList-detail">
                <p>银行退票人员 {{num.backBank}} 人</p>
                <p>雇员银行信息错误，导致出现退票</p>
              </div>
            </div>
          </el-col>
          <el-col :span="12" v-if="userInfo.userType!='2' && !this.onlyFinanceAuthor">
            <div class="processList" @click="_toPage(num.needDownBatch,'/consignee/batchAction_loadDownSendInfo.action?isDown=MA--')">
              <span class="processList-icon processListC3">
                 <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-xiazai-2"></use>
                  </svg>
              </span>
              <div class="processList-detail">
                <p>待下载发放批次 {{num.needDownBatch}} 人</p>
                <p>受托发放未下载批次数</p>
              </div>
            </div>
          </el-col>
          <el-col :span="12" v-if="userInfo.userType!='2' && !this.onlyFinanceAuthor">
            <div class="processList"  @click="_toPage(num.needGrantBatch,'/consignee/batchAction_loadDownSendInfo.action')">
              <span class="processList-icon processListC4">
                 <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-fafangguanli"></use>
                  </svg>
              </span>
              <div class="processList-detail">
                <p>待确认发放批次 {{num.needGrantBatch}} 次</p>
                <p>确认客户雇员的薪资发放状态</p>
              </div>
            </div>
          </el-col>
          <el-col :span="12" v-if="userInfo.userType!='2' &&  !this.onlyFinanceAuthor">
            <div class="processList"  @click="_toPage(num.needGrantAgreement,'/agreement/agreement_agreementIndex.action')"  >
              <span class="processList-icon processListC3">
                 <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-daiqueren"></use>
                  </svg>
              </span>
              <div class="processList-detail">
                <p>待确认协议数 {{num.needGrantAgreement}} 个</p>
                <p>为受托协议确认提醒</p>
              </div>
            </div>
          </el-col>
<!--          <el-col :span="12">-->
<!--            <div class="processList" @click="_toPage(num.needPerfectEmp,'/trusted/trustedImport_empInfoWarn.action')" >-->
<!--              <span class="processList-icon processListC4">-->
<!--                 <svg class="icon" aria-hidden="true">-->
<!--                    <use xlink:href="#icon-wanshanziliao"></use>-->
<!--                  </svg>-->
<!--              </span>-->
<!--              <div class="processList-detail">-->
<!--                <p>待完善雇员 {{num.needPerfectEmp}} 人 </p>-->
<!--                <p>确认客户雇员的薪资发放状态</p>-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-col>-->
        </el-row>
        <el-row :gutter="20" class="charts">
          <el-col :span="12">
            <div class="home-title">客户统计</div>
            <div class="chart">
              <div id="zhexian"></div>
            </div>

          </el-col>
          <el-col :span="12">
            <div class="home-title">发薪日统计</div>
            <div class="chart">
              <div id="zhuxing"></div>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="7">
        <el-row>
          <el-col :span="24">
            <div class="calendarBox">
              <div class="home-title">日历</div>
              <div class="calendarSelect">
                <p>计税地区：</p>
                <el-select class="calendarSelect-city" v-model="taxArea" @change="_getOrg" filterable  placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              <!--  <p style="width:50px;">机构：</p>
                <el-select class="calendarSelect-type" v-model="orgId" value-key="ORGID" @change="_getPeriod" placeholder="请选择">
                  <el-option
                    v-for="item in orgs"
                    :key="item.ORGID"
                    :label="item.ORGNAME"
                    :value="item.ORGID">
                  </el-option>
                </el-select>-->
              </div>
              <calendar :range="calendar.range" :lunar="calendar.lunar" :value="calendar.value" @selectMonth="selectMonth" :currentMonth="currentMonth"></calendar>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="quickBox">
              <quickAccess :showMask.sync="showMask"></quickAccess>
            </div>
          </el-col>
        </el-row>

      </el-col>
    </el-row>
    <div class="Mask" v-if="showMask"></div>

    <div>
      <el-dialog
        title="温馨提示"
        :visible.sync="this.dialogVisible"
        width="30%"
        :before-close="handleClose">
      <span><br>如果适用<span style="color: #cc0000">上一年度各月均有收入并且总收入不超过6万</span>规则，请在<span style="color: #cc0000"><b>客户管理—预扣预缴</b></span>
        处导入适用员工信息，该项目<span style="color: #cc0000"><b>仅在每年1月税金所属期开放修改，提交数据后无法修改，请慎重选择。</b></span>不导入员工信息按照不使用6万规则计算。</span>
        <span slot="footer" class="dialog-footer">
        <el-form ref="form" :model="form" label-width="10px">
          <el-form-item style="margin-bottom: -15px; margin-right: 20px">
            <el-button style="float: right" type="primary" @click="_save">我知道了</el-button>
          </el-form-item>
          <el-form-item style="margin-top: -25px; width: 50%">
            <el-checkbox-group style="float: left" v-model="form.type">
             <el-checkbox label="本年度不再提示" name="type"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </span>
      </el-dialog>

<!--      <el-dialog-->
<!--        title="温馨提示"-->
<!--        :visible.sync="this.updPwdDialogVisible"-->
<!--        width="30%"-->
<!--        :before-close="handleClose">-->
<!--        <span style="color: #cc0000"><b>您好，您的密码已超过90天未作修改或者仍在使用默认密码，为避免账户被锁定，请尽快修改密码。</b></span>-->
<!--        <span slot="footer" class="dialog-footer">-->
<!--        <el-form ref="form" :model="form" label-width="10px">-->
<!--          <el-form-item style="margin-bottom: -15px; margin-right: 20px">-->
<!--            <el-button style="float: right" type="primary" @click="_savePwd">我知道了</el-button>-->
<!--          </el-form-item>-->
<!--          <el-form-item style="margin-top: -25px; width: 50%">-->
<!--            <el-checkbox-group style="float: left" v-model="form.type">-->
<!--&lt;!&ndash;             <el-checkbox label="本年度不再提示" name="type"></el-checkbox>&ndash;&gt;-->
<!--            </el-checkbox-group>-->
<!--          </el-form-item>-->
<!--        </el-form>-->
<!--      </span>-->
<!--      </el-dialog>-->

      <div>
        <el-dialog
          title="当前为默认密码或超过90天未修改密码，请修改！"
          :visible.sync="updPwdDialogVisible"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          width="35%"
        >
          <div>
            <el-form
              :model="formPassword"
              :rules="passRules"
              ref="formPassword"
              label-width="120px"
            >
              <el-form-item label="原始密码" prop="oldPwd">
                <el-input
                  class="securityInput"
                  type="password"
                  v-model="formPassword.oldPwd"
                  placeholder="请输入旧密码"
                ></el-input>
              </el-form-item>
              <el-form-item
                :label="havePwd ? '新密码:' : '密码:'"
                prop="newPassword"
              >
                <el-input
                  class="securityInput"
                  type="password"
                  v-model="formPassword.newPassword"
                  :placeholder="havePwd ? '请输入新密码' : '请输入密码'"
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码:" prop="changepsd">
                <el-input
                  class="securityInput"
                  type="password"
                  v-model="formPassword.changepsd"
                  placeholder="请重新输入新密码"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button
              type="primary"
              :loading="buttonLoading"
              @click="savePwd('formPassword')"
            >保存</el-button
            >
          </div>
        </el-dialog>
      </div>

      <el-dialog
        title="公告提示"
        :visible.sync="this.noticeDialogVisible"
        width="30%"
        :before-close="handleNoticeClose"
        append-to-body
      >
        <ul class="noticeList">
          <li class="noticeItem" v-for="(item,index) in this.noticelist" :key="item.ID">
            <router-link :to="{path:'/sysMaintenance/pub_notice_detail', query:{id:item.ID}}">
              <i class="icon-notice el-icon-alarm-clock"></i>
              <span>{{index+1}}、</span>
              <span >{{ item.TITLE }}</span>
            </router-link>
          </li>
        </ul>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import {getMyInfo, changePassword} from '@/api/user/user';
  import BspTable from "@/components/bsp/bsp-table/bsp-table";
  import BspTableColumn from "@/components/bsp/bsp-table-column/bsp-table-column";
  import calendar from "@/components/bsp/bsp-calendar/calendar.vue";
  import quickAccess from "./quick-access.vue";
  import {
    getHomeNum,
    getOrg,
    endTax,
    getUserReport,
    getPayTime,
    visible6W,
    setUnvisible6W,
    visibleNotice,
    updPwdMsg
  } from '@/api/home/home'
  import {mapGetters} from "vuex";
  // 引入echart
  //引入基本模板
  let echarts = require("echarts/lib/echarts");

  // 引入折线图等组件
  require("echarts/lib/chart/line");
  require("echarts/lib/chart/bar");
  require("echarts/lib/chart/pie");

  // 引入提示框和title组件，图例
  require("echarts/lib/component/tooltip");
  require("echarts/lib/component/title");
  require("echarts/lib/component/legend");
  require("echarts/lib/component/legendScroll");//图例翻译滚动


  export default {
    computed: {
      ...mapGetters(['userInfo']),
    },
    components: {
      calendar,
      quickAccess,
      BspTable,
      BspTableColumn
    },
    name: "index",
    data() {
      const validatePassold = (rule, value, callback) => {
        if (value === undefined) {
          return callback(new Error("请输入密码"));
        } else if (value.length < 6) {
          return callback(new Error("密码至少为6位"));
        }
        return callback();
      };
      const validatePass = (rule, value, callback) => {
        if (value === undefined) {
          return callback(new Error('请输入密码'));
        }  else if (!/(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,20}/.test(value)) {
          return callback(new Error('密码中必须包含字母、数字、特殊字符'));
        }
        return callback();
      };
      const validatePass2 = (rule, value, callback) => {
        if (value === undefined) {
          return callback(new Error('请再次输入密码'));
        }else if (!/(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,20}/.test(value)) {
          return callback(new Error('密码中必须包含字母、数字、特殊字符'));
        }
        if (value !== this.formPassword.newPassword) {
          return callback(new Error('两次输入密码不一致!'));
        }
        return callback();
      };
      return {
        passRules: {
          oldPwd: [{ validator: validatePassold, trigger: "blur" }],
          password: [{ validator: validatePass, trigger: 'blur' }],
          newPassword: [{ validator: validatePass, trigger: 'blur' }],
          changepsd: [{ validator: validatePass2, trigger: 'blur' }],
        },
        formPassword:{},
        num: {
          cusHandleNum: 0, //本月执行客户数
          cusHandEmpNum: 0,//本月执行客雇员数
          wtBatchNum: 0,//委托批次
          wtCusNum: 0,//委托客户数
          wtEmpNum: 0,//委托雇员数
          stCusNum: 0,//受托客户数
          stEmpNum: 0,//受托人员数
          stBatchNum: 0, //受托批次数
          backBatch: 0, // 退回批次数
          backBank: 0, // 退票人数
          needDownBatch: 0,// 待下载发放批次
          needGrantBatch: 0,// 待发放批次
          needGrantAgreement: 0, // 待确认协议数
          needPerfectEmp:0//雇员信息完善提醒
        },
        calendar: {
          range: true,
          value: [

          ], //默认日期
          lunar: true, //显示农历
          currentMonth:""
        },
        options: [
         ],
        orgs:[],
        taxArea: "",
        orgId: "",
        barDataAxis: ["款到次日发薪","1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30","31"],
        barData: [],
        baryMax: 500,
        showMask: false,
        wtCus:[],
        stCus:[],
        onlyFinanceAuthor: false,
        dialogVisible: false,
        updPwdDialogVisible: false,
        noticeDialogVisible: false,
        noticelist:[],
        loading:false,

        form: {
          type: '',
        },
      }
    },
    created() {
      // 当前时间
      let now = new Date();
      this.currentMonth = now.getFullYear() +"-"+this.zeroPad( now.getMonth()+1);
      this._isOnlyFinanceAuthor();
      // 获取对应的数字
      this._getHomeNum();
    },
    mounted() {
      // 折线图
      this.drawZhexian();
      this.drawZhuxing();
      this.visible6W();
      // this.updPwdMsg();
      //公告提示
      this.visibleNotice();
    },
    methods: {
      savePwd(formName) {
        const set = this.$refs;
        set[formName].validate((valid) => {
          if (valid) {
            this.buttonLoading = true;
            let password = {
              loginName: this.userInfo.loginName,
              loginPwd: this.formPassword.newPassword,
              oldPwd: this.formPassword.oldPwd,
            };
            changePassword(password)
              .then((res) => {
                this.dialogFormVisible = false;
                this.dialogFormVisible = false;
                this.buttonLoading = false;
                if (res.data.code === 0) {
                  this.$message.success("修改密码成功,请重新登录");
                  this.$refs[formName].resetFields();
                  this.formPassword = {};
                  const pathStr = `/login`;
                  this.$store.dispatch("LogOut").then(() => {
                    this.$router.push({ path: pathStr });
                  });
                } else {
                  this.$message.warning(res.data.msg);
                }
              })
              .catch((fail) => {
                this.buttonLoading = false;
              });
          } else {
            return false;
          }
        });
      },

      loadList() {
        this.loading = true;
        visibleNotice(this.userInfo).then(res => {
          this.noticelist = res.data.data;
          this.loading = false;
          this.multipleSelection = []
        })
      },


      async visibleNotice(){
        let isvisible = await visibleNotice(this.userInfo);
        if(isvisible.data.data.length!=0){
          this.noticelist = isvisible.data.data;
          this.noticeDialogVisible = true;
        } else {
          this.noticeDialogVisible = false;
        }
      },

        async visible6W(){
            let isvisible = await visible6W(this.userInfo);
            if('00901' === isvisible.data.data){
                this.dialogVisible = true;
            } else {
                this.dialogVisible = false;
            }
        },

      async updPwdMsg(){
        let isvisible = await updPwdMsg(this.userInfo);
        if('00900' === isvisible.data.data){
          this.updPwdDialogVisible = true;
        } else {
          this.updPwdDialogVisible = false;
        }
      },



        handleClose(){
            this.dialogVisible = false;
        },
      handleNoticeClose(){
        this.noticeDialogVisible = false;
      },
        _toPage(num,path){
            this.$router.push({path: path})
        },
        _save() {
            if(this.form.type){
                this.$confirm('确认关闭年度提示？').then(_ => {
                    setUnvisible6W(this.userInfo);
                }).catch(_ => {});
            }
            this.dialogVisible = false;
        },
      _savePwd() {
        this.$router.push({
          path: "/person/edit",

        });
        this.updPwdDialogVisible = false;
      },
      _isOnlyFinanceAuthor(){
        this.onlyFinanceAuthor = (this.userInfo.userRoleList.length === 1 && "4d3060735c5467fdded5cd4dbc897ef8" === this.userInfo.userRoleList[0].roleId);
      },
      async _getHomeNum() {
        let res = await getHomeNum();
        this.num = res.data.data;
        this.taxArea = res.data.data.taxArea;
        this.options = res.data.data.areaList;
        this._getOrg();
      },
      async _getOrg(){
        if(this.taxArea){
            let  res  = await  getOrg(this.taxArea);
            this.orgs = res.data.data ;
            if(res.data.data){
              this.orgId = res.data.data[0].ORGID;
            }
          this._getPeriod();
        }
      },
      async  _getPeriod(){
         //1. 根据月份
        let  res  = await  endTax({
          month : this.currentMonth ,
          orgId: this.orgId,
          taxArea:this.taxArea
        });
        if(res.data.data&&res.data.data.length > 0){
          this.calendar.value  = res.data.data;
        }else{
          this.calendar.value = []
        }
      },
      // 画折线图
      async  drawZhexian() {
        let res = await getUserReport();
        this.wtCus = res.data.data.wtCusNum;
        this.stCus= res.data.data.stCusNum;
        var that = this;
        let myChart = echarts.init(document.getElementById("zhexian"));
        let option = {
          tooltip: {
            trigger: "axis"
          },
          legend: {
            x: "center",
            y: "bottom",
            data: ["委托", "受托"]
          },
          grid: {
            top: "8%",
            left: "5%",
            right: "5%",
            bottom: "8%",
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            z: 10,
            offset:1,
            data: ["1月", "2月", "3月", "4月", "5月", "6月", "7月","8月", "9月", "10月", "11月", "12月"]
          },
          yAxis: {
            name:"客户数/个",
            axisLine: {
              show: true,
              lineStyle:{
                color: "#ccc"
              }
            },
            axisTick: {
              show: false
            },
            min: 0,
            minInterval: 1,
            axisLabel: {
              textStyle: {
                color: "#999"
              }
            }
          },
          series: [
            {
              name: "委托",
              type: "line",
              symbol: "circle",
              symbolSize: 8,
              itemStyle: {
                normal: {
                  color: "#1890ff",
                  borderColor: "#1890ff"  //拐点边框颜色
                }
              },
              data: this.wtCus
            },
            {
              name: "受托",
              type: "line",
              symbol: "circle",
              symbolSize: 8,
              itemStyle: {
                normal: {
                  color: "#2fc25b",
                  borderColor: "#2fc25b"  //拐点边框颜色
                }
              },
              data: this.stCus
            }
          ]
        };
        myChart.setOption(option);
      },
      async  drawZhuxing() {
        let res = await getPayTime();
        this.barData = res.data.data;
        var that = this;
        let myChart = echarts.init(document.getElementById("zhuxing"));
        var dataAxis = this.barDataAxis;
        var data = this.barData;
        var yMax = this.baryMax;

        let option = {
          tooltip: {
            trigger: "axis",
            formatter: '发薪日：{b}日 <br/>协议数：{c}个'
          },
          grid: {
            top: "8%",
            left: "5%",
            right: "8%",
            bottom: "3%",
            containLabel: true
          },
          xAxis: {
            name:"日",
            offset:0,
            data: dataAxis,
            axisTick: {
              show: false
            },
            axisLabel:{
              interval: 1,
              rotate:-28,
              // formatter:function(val){
              //   if(val.length > 2){
              //     return val.split("").join("\n");
              //   }else{
              //     return val;
              //   }
              //
              // }

            },
            axisLine: {
              show: false,
            },
            z: 10
          },
          yAxis: {
            name:"协议数/个",
            axisLine: {
              show: true,
              lineStyle:{
                color: "#ccc"
              }
            },
            axisTick: {
              show: false
            },
            min: 0,
            minInterval: 1,
            axisLabel: {
              textStyle: {
                color: "#999"
              }
            }
          },
          series: [
            {
              name:"协议数",
              type: "bar",
              itemStyle: {
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 0, color: "#83bff6"},
                    {offset: 0.5, color: "#188df0"},
                    {offset: 1, color: "#188df0"}
                  ]
                )
              },
              emphasis: {
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      {offset: 0, color: "#2378f7"},
                      {offset: 0.7, color: "#2378f7"},
                      {offset: 1, color: "#83bff6"}
                    ]
                  )
                }
              },
              data: data
            }
          ]
        };
        myChart.setOption(option);
      },
      selectMonth(value){
        this.currentMonth = value;
        this._getPeriod();
      },
      // 日期补零
      zeroPad(n){
        return String(n < 10 ? '0' + n : n)
      },

    }
  };
</script>

<style scoped>
  .icon-notice {
    font-size: 16px;
    margin-right: 8px;
  }
  .noticeList {
    padding: 0 20px;
  }
  .noticeItem {
    height: 30px;
    display: flex;
    align-items: center;
  }
  .calendarBox {
    position: relative;
  }

  .business {
    background: #ffffff;
    height: 96px;
    box-shadow: 0px 1px 4px 0px rgba(140, 140, 140, 0.26);
    border-radius: 4px;
    padding: 10px;
  }

  .business p {
    line-height: 32px;
    display: flex;
    justify-content: space-between;
  }

  .business p span {
    font-size: 14px;
    color: #999;
  }

  .business p span + span {
    font-size: 20px;
  }

  .business p .orange {
    color: #FF8679;
  }

  .business p .green {
    color: #26DBE1;
  }

  .business p .blue {
    color: #2821CD;
  }

  .charts {
    /*height: 400px;*/
  }

  .chart {
    padding: 20px;
    background: #ffffff;
  }

  #zhexian, #zhuxing {
    height: 400px;
    width: 100%;
  }

  .processLists {
    margin-top: 20px;
    padding: 10px;
    background: #ffffff;
  }

  .processList {
    display: flex;
    align-items: center;
    height: 88px;
    padding: 0 20px;
    position: relative;
  }

  .processList:before {
    content: "";
    width: 70%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 10%;
    background-image: linear-gradient(to right, #fefefe, #D8D8D8, #fefefe);
  }

  .processListC1 {
    background-color: #CC5A56;
  }

  .processListC2 {
    background-color: #3BD49C;
  }

  .processListC3 {
    background-color: #64DDCA;
  }

  .processListC4 {
    background-color: #67A8E9;
  }

  .processListC5 {
    background-color: #A2DC39;
  }

  .processListC6 {
    background-color: #7261DB;
  }

  .processList-icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 20px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }

  .processList-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .processList-detail p {
    font-size: 18px;
    line-height: 34px;
    color: #1B2033;
  }

  .processList-detail p + p {
    font-size: 13px;
    color: #999;
    line-height: 29px;
  }
  .processList {
    cursor:pointer
  }
</style>


















