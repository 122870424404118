<template>
  <h2>
     <div class="home-title">{{title}}</div>
     <slot></slot>
  </h2>

</template>

<script>

  export default {
    name: "title",
    props:{
        title: {
            type: String,
            default: () => (
              ''
            )
        },
    },
    data(){
      return{
        // dialogheaderVisible:false,
      }
    },
    methods:{

    }
  };
</script>

<style scoped>

    h2{
        position: relative;
        left: 0;
        display: flex;
        justify-content: space-between;
        height: 50px;
        align-items: center;
        line-height: 50px;
        margin:0!important;
    }
    h2>span{
        font-size:14px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:600;
        color:rgba(51,51,51,1);
        position: relative;
        padding-left: 20px;
    }
    h2>span::after{
        content: '';
        position: absolute;
        width:7px;
        height:12px;
        background:#FF8679;
        left: 0;
        top: 4px;
    }
    h2>div span{
        font-size:13px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(45,167,224,1);
    }
</style>
